<template>
	<div class="mainTem">
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{$t('i18nn_4672c6113ffede32')}}</span>
						<!-- <el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini"
								@click="exportExcelAction()"></el-button>
						</el-tooltip> -->
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <ul class="actionConList"> -->
						<!-- <li> -->
							<el-button type="success" @click="toCreate()" size="small"
								icon="el-icon-plus">{{$t('i18nn_786593149c0bf9f8')}}</el-button>
						<!-- </li> -->
						<!-- <li> -->
							<el-button @click="delBatchAction($event,null)" type="danger" size="mini"
								icon="el-icon-minus">{{$t("FormMsg.Delete")}}</el-button>
						<!-- </li> -->
						<!-- <li> -->
							<el-button @click="printBolAction($event, null)" type="primary" size="mini"
								icon="el-icon-printer">BOL<span>{{$t('i18nn_da1ca7369f4bde15')}}</span></el-button>
						<!-- </li> -->
					
					<!-- </ul> -->
					
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>

		<div class="filterCon" style="">
			<div class="filterBarList">
				<el-menu :default-active="filterData.status" mode="horizontal" @select="handleSelectStatus">
					<el-menu-item :index="item.value" v-for="item in selectOption.statusList" :key="item.value">
						<span>
							{{i18nFormatter(item.value)}}
						</span>
					</el-menu-item>
				</el-menu>
			</div>
			<div class="filterAction">
				<!-- <ul class="actionConList">
					<li>
						<el-button type="success" @click="toCreate()" size="small"
							icon="el-icon-plus">{{$t('i18nn_786593149c0bf9f8')}}</el-button>
					</li>
					<li>
						<el-button @click="delBatchAction($event,null)" type="danger" size="mini"
							icon="el-icon-minus">{{$t("FormMsg.Delete")}}</el-button>
					</li>
					<li>
						<el-button @click="printBolAction($event, null)" type="primary" size="mini"
							icon="el-icon-printer">BOL<span>{{$t('i18nn_da1ca7369f4bde15')}}</span></el-button>
					</li>

				</ul> -->
				<ul class="filterConList">
					<li>
						<span class="">{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser ref="SelAgentUser" @change="changeAgentUser" size="small"></SelAgentUser>
					</li>
					<li>
						<span class="">{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo" size="small"></whNoSelect>
					</li>
					<!-- <li>
						<span class="filterName">{{$t('i18nn_43a3586339251494')}}</span>
						<el-date-picker size="small" v-model="filterData.commitDateArr" type="daterange" align="right" unlink-panels
							range-separator="-" :start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
							:end-placeholder="$t('i18nn_46f7be1133b3e2c6')" value-format="yyyy-MM-dd"
							format="yyyy-MM-dd" @change="initData()" :picker-options="pickerOptions">
						</el-date-picker>
					</li> -->
					<li>
						<span class="">{{$t('i18nn_61c33362d88eb0ba')}}</span>
						<el-select filterable clearable v-model="filterData.sourceType"
							:placeholder="$t('i18nn_2ad108ab2c560530')" size="small" style="width: 80px;"
							@change="initData()">
							<el-option v-for="item in selectOption.wh_plan_source_type" :key="item.code"
								:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
								<div class="sel_option_s1" style="">
									<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
								</div>
							</el-option>
						</el-select>
					</li>
					
					<li>
						<span class="">{{$t('i18nn_e1e2b21c7b4b951f')}}</span>
						<!-- <el-select filterable clearable size="small" v-model="filterData.trWay" :placeholder="$t('i18nn_2ad108ab2c560530')" @change="initData()">
							<el-option v-for="item2 in selectOption.wh_car_tms_type" :key="item2.code" :label="$Utils.i18nCodeText(item2)"
								:value="item2.code"></el-option>
						</el-select> -->
						<el-select filterable clearable v-model="filterData.trWay"
							:placeholder="$t('i18nn_2ad108ab2c560530')" size="small" style="width: 80px;"
							@change="initData()">
							<el-option v-for="item in selectOption.wh_car_tms_type" :key="item.code"
								:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
								<div class="sel_option_s1" style="">
									<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
								</div>
							</el-option>
						</el-select>
					</li>
					
					<li>
						<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
						<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
							maxlength="50" :clearable="true" @keyup.enter.native="initData()" style="width: 250px;">
						</el-input>
					</li>

					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
					<!-- <li>
						<el-button icon="el-icon-s-operation" size="small" type="primary" @click="openMoreFilter()">
						</el-button>
					</li> -->
				</ul>
			</div>
		</div>
		<!--更多筛选-->
		<!-- <el-drawer :wrapperClosable="false" custom-class="drawerFilter" :title="$t('i18nn_c4ca802cf2230b0b')" :visible.sync="drawerFilter" :direction="'rtl'">
			<div class="drawerFilterCon">
				<ul class="drawerFilterList">
					<li>
						<span class="filterName">{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser ref="SelAgentUser" @change="changeAgentUser" size="medium"></SelAgentUser>
					</li>
					<li>
						<span class="filterName">{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo" size="medium"></whNoSelect>
					</li>
					<li>
						<span class="filterName">{{$t('i18nn_43a3586339251494')}}</span>
						<el-date-picker v-model="filterData.commitDateArr" type="daterange" align="right" unlink-panels
							range-separator="-" :start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
							:end-placeholder="$t('i18nn_46f7be1133b3e2c6')" value-format="yyyy-MM-dd"
							format="yyyy-MM-dd" size="medium" @change="initData()" :picker-options="pickerOptions">
						</el-date-picker>
					</li>
					<li>
						<span class="filterName">{{$t('i18nn_66f0e38b9285894b')}}</span>
						<el-radio-group v-model="filterData.wh_car_tms_type" size="medium" @change="initData()">
							<el-radio-button border :label="''">{{$t('i18nn_16853bda54120bf1')}}</el-radio-button>
							<el-radio-button border v-for="item in selectOption.wh_car_tms_type" :key="item.code"
								:label="item.code">{{ $Utils.i18nCodeText(item) }}</el-radio-button>
						</el-radio-group>
					</li>
					<li class="filterBtnCon">
						<el-button icon="el-icon-search" size="medium" type="primary" @click="queryMoreFilter()">{{$t('i18nn_1e7246dd6ccc5539')}}</el-button>
					</li>
				</ul>
			</div>
		</el-drawer> -->

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small"
				:max-height="$store.state.frameConHeightWh1" @selection-change="handleSelectionChange"
				@row-click="handleCurrentChange">
				<el-table-column type="selection" fixed="left" align="center" width="55">
				</el-table-column>

				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>

				<el-table-column prop="planNo" :label="$t('i18nn_cc335ba8f4b2cc0d')">
					<template slot-scope="scope">
						<div>
							<!-- <el-link v-if="scope.row.planNo" type="primary" size="mini" icon=""
								@click="toDetActionView($event, scope.row, scope.$index)">
								{{ scope.row.planNo }}

							</el-link> -->
							
							<div class="link-copy" v-if="scope.row.planNo">
								<span class="link-text" @click="toDetActionView($event, scope.row, scope.$index)">
									{{ scope.row.planNo }}
								</span>
								<el-tooltip class="link-copy-icon" effect="dark" :content="$t('i18nn_29f67f0644a78bf3')" placement="top">
								  <i class="el-icon-document-copy" @click="$Utils.copyText(scope.row.planNo)"></i>
								</el-tooltip>
							</div>
							
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="statusName" :label="$t('i18nn_6cdece641436d7ab')">
					<template slot-scope="scope">
						<div>
				
							<!-- <el-tag type="info" size="mini">{{item.statusName}}</el-tag> -->
							<el-tag type="success" 
								v-if="'10' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
							<el-tag type="" 
								v-else-if="'20' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
							<el-tag type="warning" 
								v-else-if="'30' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
							<el-tag type="danger" 
								v-else-if="'40' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
							<el-tag type="info"  v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
							
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="whNo" :label="$t('i18nn_c944a6686d996ab3')">
				</el-table-column>
				
				<!-- <el-table-column prop="sourceTypeName" :label="$t('i18nn_61c33362d88eb0ba')">
					<template slot-scope="scope">
						<div>
							<el-tag size="small" effect="plain" type="primary" v-if="'30'==scope.row.sourceType">
								{{ $Utils.i18nKeyText(scope.row,'sourceTypeName') }}
							</el-tag>
							<el-tag size="small" effect="plain" type="success" v-else>
								{{ $Utils.i18nKeyText(scope.row,'sourceTypeName') }}
							</el-tag>
						</div>
						
					</template>
				</el-table-column> -->

				<!-- <el-table-column prop="trWay" :label="$t('i18nn_e1e2b21c7b4b951f')">
					<template slot-scope="scope">
						<span>{{ $Utils.i18nKeyText(scope.row,'trWayName') }}</span>
					</template>
				</el-table-column> -->
				
				<el-table-column prop="sourceTypeName" :label="$t('i18nn_e1e2b21c7b4b951f')">
					<template slot-scope="scope">
						<div class="recDet">
							<div class="recItem">
								<el-tag size="mini" effect="plain" type="primary" v-if="'2'==scope.row.trWay">
									{{ $Utils.i18nKeyText(scope.row,'trWayName') }}
								</el-tag>
								<el-tag size="mini" effect="plain" type="warning" v-else-if="'3'==scope.row.trWay">
									{{ $Utils.i18nKeyText(scope.row,'trWayName') }}
								</el-tag>
								<el-tag size="mini" effect="plain" type="success" v-else-if="'1'==scope.row.trWay">
									{{ $Utils.i18nKeyText(scope.row,'trWayName') }}
								</el-tag>
								<el-tag size="mini" effect="plain" type="danger" v-else>
									{{ $Utils.i18nKeyText(scope.row,'trWayName') }}
								</el-tag>
							</div>
							<div class="recItem">
								<el-tag size="mini" type="primary" v-if="'30'==scope.row.sourceType">
									{{ $Utils.i18nKeyText(scope.row,'sourceTypeName') }}
								</el-tag>
								<el-tag size="mini" type="success" v-else>
									{{ $Utils.i18nKeyText(scope.row,'sourceTypeName') }}
								</el-tag>
							</div>
						</div>
					</template>
				</el-table-column>

				<!-- <el-table-column prop="addrType" :label="$t('i18nn_0bbce74881c893df')">
					<template slot-scope="scope">
						<span>{{ $Utils.i18nKeyText(scope.row,'addrTypeName') }}</span>
					</template>
				</el-table-column> -->

				<el-table-column prop="trAddrCode" :label="$t('i18nn_8758fd50c87d6c9c')" min-width="200">
					<template slot-scope="scope">
						<div class="recDet">
							<div class="recItem">
								<el-tag type="success" effect="plain" size="mini" v-if="'1'==scope.row.addrType">
									{{ $Utils.i18nKeyText(scope.row,'addrTypeName') }}
								</el-tag>
								<el-tag type="primary" effect="plain" size="mini" v-else>
									{{ $Utils.i18nKeyText(scope.row,'addrTypeName') }}
								</el-tag>
							</div>
							<div class="recItem">
								<span>{{ scope.row.address }}</span>
							</div>
							<div class="recItem">
								<el-tag type="info" size="mini">
									{{scope.row.trAddrCode}}
								</el-tag>
							</div>
						</div>
					</template>
				</el-table-column>
				
				<!-- <el-table-column prop="pallteCount" :label="$t('i18nn_886ea69af099215d')">
				</el-table-column>
				<el-table-column prop="labelCount" :label="$t('i18nn_08e5062e3affb676')">
				</el-table-column> -->
				<!-- <el-table-column prop="transferDtos" :label="$t('i18nn_13247dc8e601ddef')" min-width="200">
					<template slot-scope="scope">
						<div>
							<div v-for="(item,index) in scope.row.transferDtos" :key="index">
								<div v-if="item">
									<el-tag type="" size="mini"
										v-if="'0' == item.status">{{ $Utils.i18nKeyText(item,'statusName') }}</el-tag>
									<el-tag type="success" size="mini"
										v-else-if="'10' == item.status">{{ $Utils.i18nKeyText(item,'statusName') }}</el-tag>
									<el-tag type="warning" size="mini"
										v-else-if="'20' == item.status">{{ $Utils.i18nKeyText(item,'statusName') }}</el-tag>
									<el-tag type="danger" size="mini" effect="plain"
										v-else-if="'28' == item.status">{{ $Utils.i18nKeyText(item,'statusName') }}</el-tag>
									<el-tag type="" size="mini"
										v-else-if="'30' == item.status">{{ $Utils.i18nKeyText(item,'statusName') }}</el-tag>
									<el-tag type="danger" size="mini"
										v-else-if="'35' == item.status">{{ $Utils.i18nKeyText(item,'statusName') }}</el-tag>
									<el-tag type="info" size="mini" v-else>{{ $Utils.i18nKeyText(item,'statusName') }}</el-tag>
									<span>{{item.relationNo}}</span>
								</div>
							</div>
						</div>
					</template>
				</el-table-column> -->
				
				<el-table-column prop="isOutCommName" :label="'其他'" min-width="120">
					<template slot-scope="scope">
						<div class="recDet">
							<div class="recItem">
								<span>
									出库指令
								</span>:
								<span>
									<el-tag type="" size="mini" effect="plain"
										v-if="'1' == scope.row.isOutComm">{{ $Utils.i18nKeyText(scope.row,'isOutCommName') }}</el-tag>
									<el-tag type="info" size="mini" effect="plain" v-else>{{ $Utils.i18nKeyText(scope.row,'isOutCommName') }}</el-tag>
								</span>
							</div>
							<div class="recItem">
								<span>
									预约完成
								</span>:
								<span>
									<el-tag type="" size="mini" effect="plain"
										v-if="'1' == scope.row.isPlaned">{{ $Utils.i18nKeyText(scope.row,'isPlanedName') }}</el-tag>
									<el-tag type="info" size="mini" effect="plain" v-else>{{ $Utils.i18nKeyText(scope.row,'isPlanedName') }}</el-tag>
								</span>
							</div>
							<div class="recItem">
								<span>
									换标
								</span>:
								<span>
									<el-tag type="" size="mini" effect="plain"
										v-if="'1' == scope.row.isChangeLabel">{{ $Utils.i18nKeyText(scope.row,'isChangeLabelName') }}</el-tag>
									<el-tag type="info" size="mini" effect="plain" v-else>{{ $Utils.i18nKeyText(scope.row,'isChangeLabelName') }}</el-tag>
								</span>
							</div>
						</div>
					</template>
				</el-table-column>

				<!-- <el-table-column prop="isOutCommName" :label="$t('i18nn_934695e82afbe756')">
					<template slot-scope="scope">
						<span>{{ $Utils.i18nKeyText(scope.row,'isOutCommName') }}</span>
					</template>
				</el-table-column>

				<el-table-column prop="isPlanedName" :label="$t('i18nn_1d9d50fca9ffeb39')">
					<template slot-scope="scope">
						<div>
							<el-tag type=""
								v-if="'1' == scope.row.isPlaned">{{ $Utils.i18nKeyText(scope.row,'isPlanedName') }}</el-tag>
							<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'isPlanedName') }}</el-tag>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="isChangeLabelName" :label="$t('i18nn_fa02547543cff0f7')">
					<template slot-scope="scope">
						<div>
							<el-tag type=""
								v-if="'1' == scope.row.isChangeLabel">{{ $Utils.i18nKeyText(scope.row,'isChangeLabelName') }}</el-tag>
							<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'isChangeLabelName') }}</el-tag>
						</div>
					</template>
				</el-table-column> -->
				<el-table-column prop="pallteCount" :label="$t('i18nn_886ea69af099215d')" min-width="120">
					<template slot-scope="scope">
						<div class="recDet">
							<div class="recItem">
								<span>
									{{$t('i18nn_886ea69af099215d')}}
								</span>:
								<span>
									<strong>
										{{scope.row.pallteCount}}
									</strong>
								</span>
							</div>
							<div class="recItem">
								<span>
									{{$t('i18nn_08e5062e3affb676')}}
								</span>:
								<strong>
									{{scope.row.labelCount}}
								</strong>
							</div>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="planList" :label="'柜号'+' * '+'外箱标'+' * '+'数量'+' * '+'FBA编号'+' * '+'FBA Pre#'+' * '+'重量'+' * '+'体积'" min-width="500">
					<template slot-scope="scope">
						<div v-if="scope.row.planList" class="recDet">
							<div class="recItem" v-for="(item,index) in scope.row.planList" :key="index" v-if="index<5">
								<el-tag type="primary" effect="plain" size="mini">{{item.relationNo}}</el-tag>&nbsp;*&nbsp;
								<span class="recItem-sku">{{item.ctnMark}}</span>&nbsp;*&nbsp;
								<strong class="recItem-num">{{item.quantity}}</strong>&nbsp;*&nbsp;
								<el-tag type="success" effect="plain" size="mini">{{item.fbaNo}}</el-tag>&nbsp;*&nbsp;
								<el-tag type="info" effect="plain" size="mini">{{item.fbaPre}}</el-tag>&nbsp;*&nbsp;
								<span >{{item.totalWeight}}</span>&nbsp;*&nbsp;
								<span >{{item.totalVol}}</span>
							</div>
							<div v-if="scope.row.planList.length>5">
								<el-link type="primary" @click="toDetActionView($event, scope.row, scope.$index)">
									..
									<span>{{$t('i18nn_73bbf51487ec69e9')}}</span>
									<span>&nbsp;{{scope.row.planList.length}}&nbsp;</span>
									<span>{{$t('i18nn_117aa3f27e5e430c')}}</span>
								</el-link>
							</div>
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="pallteCount" :label="$t('i18nn_886ea69af099215d')">
				</el-table-column>
				<el-table-column prop="labelCount" :label="$t('i18nn_08e5062e3affb676')">
				</el-table-column> -->
				<!-- <el-table-column prop="location" :label="$t('i18nn_3a6eb04ef8f3327b')" width="">
				</el-table-column> -->
				
				<el-table-column prop="BOL" :label="'BOL'" width="">
					<template slot-scope="scope">
						<div @click="openUploadFileBol($event, scope.row)">
							<!-- <el-button type="text" size="small"
								icon="el-icon-paperclip">BOL<span>undefined</span></el-button> -->
							<el-button type="text" size="small" icon="el-icon-paperclip">BOL附件</el-button>
							
							<div v-if="scope.row.bolFiles && scope.row.bolFiles.length>0">
								<el-link type="primary">
									...<span>{{$t('i18nn_73bbf51487ec69e9')}}</span><span>{{scope.row.bolFiles.length}}</span><span>{{$t('i18nn_7f18cb0ceb569eb0')}}</span>
								</el-link>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="POD" :label="'POD'" width="">
					<template slot-scope="scope">
						<div @click="openUploadFilePod($event, scope.row)">
							<!-- <el-button type="text" size="small"
								icon="el-icon-paperclip">POD<span>undefined</span></el-button> -->
							<div v-if="scope.row.podFiles && scope.row.podFiles.length>0">
								<el-link type="primary">
									...<span>{{$t('i18nn_73bbf51487ec69e9')}}</span><span>{{scope.row.podFiles.length}}</span><span>{{$t('i18nn_7f18cb0ceb569eb0')}}</span>
								</el-link>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="isa" :label="'ISA'" width="">
				</el-table-column>
				<el-table-column prop="pro" :label="'PRO'" width="">
				</el-table-column>
				
				<el-table-column prop="trunckingPrice" :label="$t('i18nn_6867f16a2ac5e825')" width="">
				</el-table-column>
				

				<!-- <el-table-column prop="planDate" :label="$t('i18nn_fb6105f4b294a9d0')">
				</el-table-column> -->
				
				<el-table-column prop="" :label="$t('i18nn_43a3586339251494')" min-width="220">
					<template slot-scope="scope">
						<div>
							<ul>
								<li>
									<span>{{$t('i18nn_fb6105f4b294a9d0')}}</span>:{{scope.row.planDate}}
								</li>
								<li>
									<span>{{$t('i18nn_fdc34fd7121f9c48')}}</span>:{{scope.row.updateTime}}
								</li>
								<li>
									<span>{{$t('i18nn_726c51702f70c486')}}</span>:{{scope.row.createTime}}
								</li>
							</ul>
						</div>
					</template>
				</el-table-column>
				

				<el-table-column prop="attachments" :label="$t('i18nn_f5d43732e3f6cf4d')">
					<template slot-scope="scope">
						<div @click="openViewFile($event, scope.row)">
							<!-- <div v-for="(item,index) in scope.row.attachments" :key="index" v-if="index<1">
				          <el-link type="primary">{{item.fileName}}</el-link>
				        </div> -->
							<div v-if="scope.row.attachments">
								<el-link type="primary">
									...
									<span>{{$t('i18nn_73bbf51487ec69e9')}}</span>
									<span>{{ scope.row.attachments.length }}</span>
									<span>{{ $t('i18nn_7f18cb0ceb569eb0') }}</span>
								</el-link>
							</div>
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover">
							<div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference" style="color:red;">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>
				
				

				<!-- <el-table-column prop="statusName" :label="$t('Storage.packing_list.status')">
					<template slot-scope="scope">
						<el-tag type="" v-if="'0' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="success" v-else-if="'10' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="warning" v-else-if="'60' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="danger" v-else-if="'70' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="" v-else-if="'80' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="warning" effect="plain" v-else-if="'99' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
					</template>
				</el-table-column> -->

				<el-table-column :label="$t('Storage.tableColumn.operation')" width="320px" fixed="right" align="left">
					<template slot-scope="scope">
						<div>


							<!-- <div style="margin-bottom: 5px;">
								<el-button @click="openUploadFile($event, scope.row)" type="success" size="mini"
									icon="el-icon-upload2">{{$t('FormMsg.upload_attachment')}}</el-button>
									
								<el-button @click="openQARecList($event, scope.row)"  type="primary" plain size="mini" icon="el-icon-service">{{$t('i18nn_10bc8a2b45aa5636')}}</el-button>
							</div> -->

							<!-- <div style="margin-bottom: 5px;">
								<el-button @click="updataDestination($event, scope.row)" type="primary" size="mini"
									icon="el-icon-edit">{{$t('i18nn_f4af8c42d7478c6f')}}</el-button>
							</div> -->

							<div style="margin-bottom: 5px;">
								<!-- <el-button @click="commitAction($event, scope.row)" type="primary" size="mini"
									icon="el-icon-sell">{{$t('i18nn_ad4345dbaabe1479')}}</el-button> -->
								<el-button @click="delBatchAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus"
									 v-if="'10'==scope.row.status">{{$t("FormMsg.Delete")}}</el-button>
								<el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini"
									icon="el-icon-edit"  v-if="'10'==scope.row.status">{{$t("FormMsg.Edit")}}</el-button>

								<el-button @click="commitAction($event, scope.row)" type="success" size="mini" icon="el-icon-sell"
									v-if="'1'!=scope.row.isPlaned">预约提货</el-button>
							</div>
							<div style="margin-bottom: 5px;">
								
								<el-button @click="printBolAction($event, scope.row)" type="primary" size="mini"
									icon="el-icon-printer">BOL<span>{{$t('i18nn_da1ca7369f4bde15')}}</span></el-button>
								<el-button @click="openUploadFile($event, scope.row)" type="success" size="mini"
									icon="el-icon-upload2">{{$t('FormMsg.upload_attachment')}}</el-button>
							</div>
							<!-- <div style="margin-bottom: 5px;" v-if="70==scope.row.status||80==scope.row.status">
								<el-button @click="downLoadBolFile($event, scope.row)" type="success" size="mini"
									icon="el-icon-download"><span>{{$t('i18nn_245883c80f181c4a')}}</span>BOL</el-button>
							</div> -->
							<!-- <div style="margin-bottom: 5px;" v-if="10==scope.row.status">
								<el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini"
									icon="el-icon-edit">{{$t("FormMsg.Edit")}}</el-button>
								<el-button @click="commitCancelAction($event, scope.row, scope.$index)" type="danger" size="mini" icon="el-icon-sold-out">{{$t('i18nn_b4e5993f97fe04ee')}}</el-button>
							</div> -->

						</div>

					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>

		<!-- 详情查看 -->
		<TransportPlanOutWhDet :openTime="openTimeDsDet" :row="DsDetRow"></TransportPlanOutWhDet>

		<!-- 预约完成 -->
		<TransportPlanOutWhComplate :row="editRow" :openTime="editOpenTime" @success="complateSuccess">
		</TransportPlanOutWhComplate>

		<!--  附件查看 -->
		<whFileView2 :openTime="FileViewOpenTime" :fileList="fileDataList"></whFileView2>
		<!-- 售后 -->
		<!-- <QAModelRecList
			:openTime="QAMoRecListOpenTime"
			:type="'60'"
			:relationId="QAMoRecListRelationId"
			:relationNo="QAMoRecListRelationNo"
			:whNo="QAMoRecListWhNo"
			@success="QAMoRecListSuccess"
		></QAModelRecList> -->

		<!--  更新目的地 -->
		<!-- <TransportUpdataDestination ref="TransportUpdataDestination" :openTime="TransportUpdataAdrOpenTime" :row="TransportUpdataAdrRow" @success="successSubmitDestData">
		</TransportUpdataDestination> -->

		<!--  附件上传 -->
		<whFileUpload :openTime="FileUploadOpenTime" :relationId="fileRelationId" @success="FileUploadSuccess">
		</whFileUpload>
		
		<!--  附件查看-pod -->
		<whFileView2 :openTime="PodFileViewOpenTime" :fileList="PodFileDataList"></whFileView2>
		
		<!--  附件上传-bol -->
		<whFileUploadByType :openTime="BolFileUploadOpenTime" :relationId="BolFileRelationId" :title="'BOL'"
			:typeCode="'20'" :userId="BolFileUploadUserId" @success="BolFileUploadSuccess">
		</whFileUploadByType>
	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	
	import {
		TransportPlanOutWh_status
	} from '@/i18n/langStatus.js';
	import {
		i18nStatus
	} from '@/i18n/FormatI18n.js';

	// import {
	// 	downloadPdfBuffer
	// } from '@/utils/pdfUtils.js';

	// import TransportManageEdit from '@/components/StorageCenter/Transport/TransportManageEdit.vue';
	import TransportPlanOutWhDet from '@/components/StorageCenter/Transport/TransportPlanOutWhDet.vue';

	import TransportPlanOutWhComplate from '@/components/StorageCenter/Transport/TransportPlanOutWhComplate.vue';

	import whFileView2 from '@/components/StorageCenter/components/whFileView2.vue';

	// import TransportManageDetList from '@/components/StorageCenter/Transport/TransportManageDetList.vue';
	import whFileUpload from '@/components/StorageCenter/components/whFileUpload.vue';
	import SelAgentUser from '@/components/Common/SelAgentUser.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	
	import whFileUploadByType from '@/components/StorageCenter/components/whFileUploadByType.vue';

	// import TransportUpdataDestination from '@/components/StorageCenter/Transport/TransportUpdataDestination.vue';

	// import QAModelRecList from '@/components/StorageCenter/QA/QAModelRecList.vue';

	export default {

		components: {
			// TransportUpdataDestination,
			whFileUpload,
			SelAgentUser,
			whNoSelect,
			TransportPlanOutWhDet,
			TransportPlanOutWhComplate,
			whFileView2,
			whFileUploadByType
			// QAModelRecList
		},
		data() {
			return {
				// dialogShowDet: false,
				// sendId: null,

				fileRelationId: '',
				FileUploadOpenTime: '',

				openTimeDsDet: '',
				DsDetRow: {},

				drawerFilter: false,

				//预约完成
				editRow: '',
				editOpenTime: '',

				FileViewOpenTime: '',
				fileDataList: [],
				
				PodFileViewOpenTime: "",
				// PodFileRelationId: "",
				// PodFileUploadUserId: "",
				PodFileDataList: [],
				
				BolFileUploadOpenTime: "",
				BolFileRelationId: "",
				BolFileUploadUserId: "",

				//售后
				// QAMoRecListOpenTime:"",
				// QAMoRecListRelationId:"",
				// QAMoRecListRelationNo:"",
				// // QAMoRecListUserId:"",
				// QAMoRecListWhNo:"",

				// TransportUpdataAdrOpenTime:'',
				// TransportUpdataAdrRow:{},

				pickerOptions: this.$PickerDate.pickerOptions_1(),

				multipleSelection: [],

				// loading_count: false,
				// countData: {},

				loading: false,

				loading_det: false,

				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					wh_car_tms_type: [],
					// wh_no:[],
					statusList: TransportPlanOutWh_status,
					wh_plan_source_type:[],
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序
					// "accountPeriod":"",
					// "billNo":""
					agentUser: "",
					// workNo: '',
					// status: '',
					// packingNo: '',
					keyword: "",
					// commitDateArr: [],
					whNo: '',
					trWay:'',
					sourceType:""
					// wh_car_tms_type: ''
					// putWhNo: '',
					// goodsSku: '',
					// hashCode: '',
					// goodsName: '',
					// declareNameCh: ''
				}
			};
		},

		//创建时
		created() {
			if (this.$route.query.state) {
				this.filterData.status = this.$route.query.state;
				// this.initData();
			}
			if (this.$route.query && this.$route.query.agentUser) {
				this.filterData.agentUser = this.$route.query.agentUser;
				this.$nextTick(() => {
					this.$refs.SelAgentUser.initData(this.filterData.agentUser);
				});
			}
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			//数据字典
			getDicData(['wh_car_tms_type','wh_plan_source_type'],
				(data)=>{
					this.selectOption.wh_car_tms_type = data['wh_car_tms_type'];
					this.selectOption.wh_plan_source_type = data['wh_plan_source_type'];
			});
			this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				// this.currentSel = {};
				this.multipleSelection = [];
				this.getPageData();
				// this.getCountData();
				// this.getDicData();
			},
			//打开更多筛选
			openMoreFilter() {
				this.drawerFilter = true;
			},
			//查询更多筛选
			queryMoreFilter() {
				this.initData();
				this.drawerFilter = false;
			},
			i18nFormatter(value) {
				return i18nStatus(value, this.selectOption.statusList)
			},
			changeAgentUser(val) {
				this.filterData.agentUser = val;
				this.initData();
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				this.initData();
			},

			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},

			//去详情
			toDetActionView(event, row) {
				// event.stopPropagation();
				// this.$router.push({ name: 'WhDropShippingDet', query: { orderNumber: row.orderNumber } });
				this.openTimeDsDet = new Date().getTime();
				this.DsDetRow = row;
			},

			//打开附件预览
			openViewFile(event, row) {
				event.stopPropagation();
				this.FileViewOpenTime = new Date().getTime();
				this.fileDataList = row.attachments;
			},
			
			//BOL附件查看
			openUploadFileBol(event, row) {
				event.stopPropagation();
				// this.BolFileDataList = row.bolFiles;
				this.BolFileUploadUserId = row.userId;
				this.BolFileRelationId = row.id;
				this.BolFileUploadOpenTime = new Date().getTime();
			},
			BolFileUploadSuccess(data) {
				// this.dialogFile = false;
				this.initData();
				// event.stopPropagation();
				// this.isShowFileUpload = true;
				// this.fileRelationId = row.id;
				// this.FileUploadOpenTime = new Date().getTime();
			},
			
			//POD附件
			openUploadFilePod(event, row) {
				event.stopPropagation();
				this.PodFileDataList = row.podFiles;
				this.PodFileViewOpenTime = new Date().getTime();
			},

			toCreate() {
				this.openDioalog();
			},
			//打开新增编辑，弹窗
			openDioalog() {
				this.$router.push({
					name: "TransportPlanOutWhEdit"
				})
			},
			// //打开编辑
			openEdit(event, row, index) {
				event.stopPropagation();
				this.$router.push({
					name: "TransportPlanOutWhEdit",
					query: {
						editId: row.id
					}
				})
			},

			//查看详情
			showDetList($event, row) {
				$event.stopPropagation();
				this.$router.push({
					name: "TransportOutWhDetList",
					query: {
						id: row.id
					}
				});
			},

			//跳转到转运入库
			toTraInWh($event, code) {
				$event.stopPropagation();
				this.$router.push({
					name: "TransportInWh",
					query: {
						code: code
					}
				});
			},

			//提交
			// commitAction(event, row) {
			// 	event.stopPropagation();
			// 	this.$confirm(this.$t('tips.confirmSubmit'), this.$t('tips.tipsTitle'), {
			// 			type: 'warning'
			// 		})
			// 		.then(() => {

			// 			this.postData(this.$urlConfig.WhTransferOutCommit, {
			// 				id: row.id
			// 			},'',()=>{});
			// 		})
			// 		.catch(() => {});
			// },
			//删除
			// delAction(event, row) {
			// 	event.stopPropagation();
			// 	this.$confirm(this.$t("FormMsg.confirm_Delete"), this.$t('tips.tipsTitle'), {
			// 			type: 'warning'
			// 		})
			// 		.then(() => {
			// 			this.postData(this.$urlConfig.WhTransferOutDel + '/' + row.id, {}, 'delete',()=>{});
			// 		})
			// 		.catch(() => {});
			// },
			//删除批量
			delBatchAction(event, row) {
				event.stopPropagation();

				let parm = [];

				if (row) {
					//     //单条
					parm = [row.id];
				} else {
					//多条
					let dataList = this.multipleSelection;
					if (dataList.length < 1) {
						this.$message.warning(this.$t('Storage.DropShipping.sel_tips_1'));
						return;
					}
					let dataListParm = dataList.map(v => v.id);
					parm = dataListParm;
				}

				this.$confirm(this.$t('Storage.DropShipping.sure_submit_1') + parm.length + this.$t(
						'Storage.DropShipping.sure_submit_2'), this.$t('tips.tipsTitle'), {

						type: 'warning'
					})
					.then(() => {
						this.postData(this.$urlConfig.WhTransferPlanOutWhDel, {
							"ids": parm
						}, '', () => {});
					})
					.catch(() => {});
			},

			//预约提货
			commitAction(event, row) {
				event.stopPropagation();
				this.editOpenTime = new Date().getTime();
				this.editRow = row;
				// this.PalletComplateType = 1;
			},
			//预约完成，回调
			complateSuccess() {
				this.initData();
			},

			//BOL打印
			printBolAction(event, row) {
				event.stopPropagation();
				let ids = [];
				if (row) {
					ids = [row.id];
				} else {
					if (this.multipleSelection.length <= 0) {
						this.$message.warning(this.$t('i18nn_fc089dc894530f12'));
						return;
					}
					ids = this.multipleSelection.map(item => item.id).join(',');
					// this.$router.push({name:"whPriterTransportPlanOutList",ids:ids});
				}

				let routeData = this.$router.resolve({
					name: 'whPriterTransportPlanOutList',
					query: {
						ids: ids
					}
				});
				window.open(routeData.href, '_blank');

			},

			//撤销
			// commitCancelAction(event, row) {
			//   event.stopPropagation();

			//   let parm = [];

			//     if (row) {
			//   //     //单条
			//       parm = [row.id];
			//     } else {
			//       //多条
			//       let dataList = this.multipleSelection;
			//       if (dataList.length < 1) {
			//         this.$message.warning(this.$t('Storage.DropShipping.sel_tips_1'));
			//         return;
			//       }
			//       let dataListParm = dataList.map(v => v.id);
			//       parm = dataListParm;
			//     }

			//   this.$confirm(this.$t('Storage.DropShipping.sure_submit_1')+parm.length+this.$t('Storage.DropShipping.sure_submit_2'), this.$t('tips.tipsTitle'), {

			//     type: 'warning'
			//   })
			//     .then(() => {
			//       this.postData(this.$urlConfig.WhTransferOutRemove, {"ids":parm},'',()=>{});
			//     })
			//     .catch(() => {});
			// },

			//售后
			// openQARecList(event, row){
			// 	event.stopPropagation();
			// 	this.QAMoRecListRelationId = row.id;
			// 	this.QAMoRecListRelationNo = row.outWhCode;
			// 	// this.QAMoRecListUserId = row.userId;
			// 	this.QAMoRecListWhNo = row.whNo;
			// 	this.QAMoRecListOpenTime = new Date().getTime();
			// },
			// QAMoRecListSuccess() {
			// 	this.initData();
			// },


			//提交信息
			postData(url, formData, type, callback) {
				// HttpTypelet _this = this;
				this.loading = true;
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					
					this.loading = false;
					this.loading_load = false;
					if (200 == data.code) {
						// this.dialogFormVisible = false;
						this.initData();
						if ("delete" == type) {
							this.$message({
								type: 'success',
								message: this.$t('tips.successfullyDelete')
							});
						} else {
							this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
								type: 'success'
							});
						}
						callback();
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},

			handleClick(tab, event) {
				console.log(tab, event);
				// this.form.sendRecordDtoList = [];
			},

			handleSelectStatus(key, keyPath) {
				// console.log(key, keyPath);
				this.filterData.status = key;
				this.initData();
			},

			//统计状态数量
			// getCountData() {
			// 	this.loading_count = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhTransferOutStatusCount, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			
			// 			this.loading_count = false;
			// 			if (200 == data.code) {
			// 				//表格显示数据
			// 				this.countData = data.data;
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : '状态统计，查询失败');
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.$message.error('状态统计，查询失败！');
			// 			this.loading_count = false;
			// 		});
			// },

			//分页的筛选项数据
			pageFilterData() {

				// let commitDateArr = this.filterData.commitDateArr;
				// let startCommitDate = '';
				// let endCommitDate = '';
				// if (commitDateArr && commitDateArr.length == 2) {
				// 	startCommitDate = commitDateArr[0];
				// 	endCommitDate = commitDateArr[1];
				// }

				return {
					// workNo: this.filterData.workNo ? this.filterData.workNo : null,
					proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					status: this.filterData.status ? this.filterData.status : null,
					// packingNo: this.filterData.packingNo ? this.filterData.packingNo : null,
					keyword: this.filterData.keyword ? this.filterData.keyword : null,
					// // putWhNo: this.filterData.putWhNo ? this.filterData.putWhNo : null,
					whNo: this.filterData.whNo ? this.filterData.whNo : null,

					// startCommitDate: startCommitDate ? startCommitDate : null,
					// endCommitDate: endCommitDate ? endCommitDate : null,

					trWay: this.filterData.trWay ? this.filterData.trWay : null,
					sourceType: this.filterData.sourceType ? this.filterData.sourceType : null,
					// hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
					// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
					// declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
				};
			},
			//请求分页数据
			getPageData() {

				let filterData = Object.assign({
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size //当前页显示数目
					},
					this.pageFilterData()
				);

				// let _this = this;
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhTransferPlanOutWhPageList, filterData)
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},

			//打开附件上传
			openUploadFile(event, row) {
				event.stopPropagation();
				// this.isShowFileUpload = true;
				this.fileRelationId = row.id;
				this.FileUploadOpenTime = new Date().getTime();
			},
			//附件上传成功回调
			FileUploadSuccess(data) {
				this.initData();
				// event.stopPropagation();
				// this.isShowFileUpload = true;
				// this.fileRelationId = row.id;
				// this.FileUploadOpenTime = new Date().getTime();
			},

			//更新目的地
			// updataDestination(event, row){
			// 	event.stopPropagation();
			// 	this.TransportUpdataAdrOpenTime = new Date().getTime();
			// 	this.TransportUpdataAdrRow = row;
			// },

			// successSubmitDestData(fbaAddrCode) {
			// 	let formData = {
			// 		"id": this.TransportUpdataAdrRow.id,
			// 		"fbaAddrCode": fbaAddrCode
			// 	};
			// 	console.log('formData', formData);
			// 	this.postData(this.$urlConfig.WhTransferUpdataAddress, formData,'',()=>{
			// 		this.$nextTick(()=>{
			// 			this.$refs.TransportUpdataDestination.close();
			// 		})
			// 	});
			// },

			//下载BOL
			// downLoadBolFile(event, row){
			// 	event.stopPropagation();
			// 	this.loading_load = true;
			// 	downloadPdfBuffer(this.$urlConfig.WhTransferOutDownloadBol+"/"+row.id,`BOL_${row.outWhCode}_${new Date().getTime()}`);
			// 	this.loading_load = false;
			// },

			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_car_tms_type'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				// this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			// 				this.selectOption.wh_car_tms_type = data.data['wh_car_tms_type'];
			// 				// this.selectOption.biz_wh_tr_plan_addr_type = data.data['biz_wh_tr_plan_addr_type'];
			// 				// this.selectOption.wh_no = data.data['wh_no'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t("tips.submitError");
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t("tips.submitRequestError"));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// /deep/ .el-input-number,
	// .el-select {

	// 	// width: 100px;
	// 	.el-input__inner {
	// 		text-align: left;
	// 	}
	// }

	// .form_msg {
	// 	color: #e6a23c;
	// }
</style>